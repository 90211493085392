<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <div class="flex">
            <div class="w-full">

                <form @submit.prevent="update(schedule)" @keydown="removeError($event.target.id)">
                    <a-card>
                        <h3>Actualizar horario</h3>
                        <div class="flex flex-wrap -mx-2">
                            <div class="w-full md:w-1/2 lg:w-1/2">
                                <div class="form-group">
                                    <label for="code">Día {{ schedule.day }}</label>
                                    <a-select
                                        :error="error('day', { attribute: 'día' })"
                                        :source="days" 
                                        v-model="schedule.day"/>
                                </div>
                                <div class="form-group">
                                    <label for="classroom_id">aula de clase</label>
                                    <a-select
                                        id="classroom_id"
                                        :loading="loadingClassrooms"
                                        :source="classrooms.map(it => ({ label: it.code, value: it.id }))"
                                        :error="error('classroom_id', { attribute: 'aula de clase' })"
                                        v-model="schedule.classroom_id" />
                                </div>
                                <div class="form-group">
                                    <label for="class_hour_id">horario de clase {{ schedule.class_hour_id }}</label>
                                    <a-select
                                        id="class_hour_id"
                                        :loading="loadingClasshours"
                                        :source="mappedClassHours"
                                        :error="error('class_hour_id', { attribute: 'horario de clase' })"
                                        v-model="schedule.class_hour_id" />
                                </div>
                            </div>
                        </div>

                        <template #footer>
                            <a-button class="mr-2" type="submit" :loading="loading" :disabled="loading || loadingClasshours || loadingClassrooms">
                               Actualizar cambios
                            </a-button>

                            <a-button outlined :to="{ name: 'schedules' }">
                                Cancelar
                            </a-button>
                        </template>
                    </a-card>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        schedule: {
            day: null,
            course_section_id: null,
            classroom_id: null,
            class_hour_id: null,
            period_id: null
        },
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error'
        }),
        ...mapState({
            schedules: state => state.schedules.pagination.data,
            currentScheduleOnState: state => state.schedules.current,
            currentCourseSectionOnState: state => state.sections.courseSection,
            loading: state => state.schedules.loading,
            classrooms: state => state.classrooms.all,
            loadingClassrooms: state => state.classrooms.loading,
            classhours: state => state.classhours.all,
            loadingClasshours: state => state.classhours.loading
        }),
        days() {
            return [
                { label: 'Lunes', value: 1 },
                { label: 'Martes', value: 2 },
                { label: 'Miércoles', value: 3 },
                { label: 'Jueves', value: 4 },
                { label: 'Viernes', value: 5 },
            ]
        },
        mappedClassHours() {
            return this.classhours.map(it => { 
                const start_hour = this.$options.filters.timePadding(it.start_hour)
                const start_minute = this.$options.filters.timePadding(it.start_minute)
                const end_hour = this.$options.filters.timePadding(it.end_hour)
                const end_minute = this.$options.filters.timePadding(it.end_minute)
                return {
                    label: `${start_hour}:${start_minute} / ${end_hour}:${end_minute}`, 
                    value: it.id 
                }
            })
        }
    },
    methods: {
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
        ...mapActions({
            fetchSchedule: 'schedules/fetch',
            update: 'schedules/update',
            removeError: 'removeError',
            fetchCourseSections: 'sections/fetchCourseSections',
            fetchClassrooms: 'classrooms/index',
            fetchClasshours: 'classhours/index',
        })
    },
    watch: {
        currentScheduleOnState(val) {
            this.schedule = {...val}
        },
        currentCourseSectionOnState(val) {
            this.schedule.course_section_id = val.id
        }
    },
    created() {
        this.setNavbarTitle(['Listado de horarios', 'Crear horario'])

        this.fetchCourseSections({
            ...this.$route.params,
            filters: {}
        })

        this.fetchClassrooms({ limit: 0, enabled: true })
        this.fetchClasshours({ limit: 0, enabled: true })

        const id = this.$route.params.schedule_id
        if (id) {

            const found = this.schedules.find(schedule => schedule.id === parseInt(id))

            if (found) {
                this.schedule = {...found}
                this.setNavbarTitle(['Listado de horarios', found.title, 'Editar horario'])
            } else {
                this.fetchSchedule(id)
            }
        }
    }
}
</script>
